import { InferGetServerSidePropsType } from 'next'
import { createContext, useEffect } from 'react'
import { HomepageMobile } from 'components/organisms'
import { defaultCity, getCity } from 'utils/hooks/useGetCity'
import { useCar } from 'services/context/carContext'
import { useUtils } from 'services/context/utilsContext'
import {
  Article,
  Banner,
  MobileWebTopMenuType,
  TestimonialData,
} from 'utils/types/utils'
import Script from 'next/script'
import { getToken } from 'utils/handler/auth'
import {
  getRecommendation,
  getBanner,
  getMobileHeaderMenu,
  getCities,
  getTestimony,
  getUsage,
  getMainArticle,
  getTypeCar,
  getCarofTheMonth,
  getAnnouncementBox as gab,
  getMobileFooterMenu,
  getMinMaxYearsUsedCar,
  getModelUsedCar,
  getUsedCarSearch,
  getPromoBanner,
  getMinMaxPrice,
} from 'services/api'
import ReportSentry from 'utils/handler/sentry'
import { logAmplitudeEvent } from 'services/amplitude'
import { TrackingEventName } from 'services/amplitude/types'
import { AmplitudeEventName } from 'services/amplitude/types'
import { getCookies, setCookies } from 'utils/handler/cookies'
import { CookieKey } from 'utils/enum'
import { hasCookie } from 'cookies-next'

interface HomePageDataLocalContextType {
  dataBanner: any
  dataMobileMenu: MobileWebTopMenuType[]
  dataCities: any
  dataTestimony: any
  dataUsage: any
  dataDealerArticle: any
  dataTypeCar: any
  dataCarofTheMonth: any
  dataFooterMenu: any
  dataMinMaxYearUsedCar: any
  dataModelUsedCar: any
}
/**
 * used to pass props without drilling through components
 */
export const HomePageDataLocalContext =
  createContext<HomePageDataLocalContextType>({
    dataBanner: null,
    dataMobileMenu: [],
    dataCities: null,
    dataTestimony: null,
    dataUsage: null,
    dataDealerArticle: null,
    dataTypeCar: null,
    dataCarofTheMonth: null,
    dataFooterMenu: [],
    dataMinMaxYearUsedCar: null,
    dataModelUsedCar: [],
  })

export default function WithTracker({
  dataReccomendation,
  dataBanner,
  dataMobileMenu,
  dataCities,
  dataTestimony,
  dataUsage,
  dataMainArticle,
  dataDealerArticle,
  dataTypeCar,
  dataCarofTheMonth,
  dataMinMaxYearUsedCar,
  dataModelUsedCar,
  ssr,
  dataFooterMenu,
  dataSearchUsedCar,
  dataMinMaxPrice,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { saveTypeCar, saveCarOfTheMonth, saveRecommendation } = useCar()
  const {
    saveArticles,
    saveDealerArticles,
    saveMobileWebTopMenus,
    saveDataAnnouncementBox,
    saveMobileWebFooterMenus,
    saveDataSearchUsedCar,
    saveCities,
    saveTestimonial,
  } = useUtils()

  const getAnnouncementBox = async () => {
    try {
      const res: any = await gab({
        headers: {
          'is-login': getToken() ? 'true' : 'false',
        },
      })
      saveDataAnnouncementBox(res.data)
    } catch (error) {
      ReportSentry(`Index Pages - getAnnouncementBox`, error)
    }
  }

  const trackAmplitudeEvent = () => {
    logAmplitudeEvent({
      name: TrackingEventName.WEB_LANDING_PAGE_VIEW,
      data: null,
    })
  }

  useEffect(() => {
    if (
      !dataMobileMenu ||
      !dataMainArticle ||
      !dataBanner ||
      !dataTestimony ||
      !dataCarofTheMonth ||
      !dataFooterMenu ||
      !dataCities ||
      !dataReccomendation
    ) {
      location.reload()
    } else {
      saveMobileWebTopMenus(dataMobileMenu)
      saveDealerArticles(dataDealerArticle)
      saveCarOfTheMonth(dataCarofTheMonth)
      saveTypeCar(dataTypeCar)
      saveMobileWebFooterMenus(dataFooterMenu)
      saveDataSearchUsedCar(dataSearchUsedCar)
      saveCities(dataCities)
      saveRecommendation(dataReccomendation)
      saveTestimonial(dataTestimony)
    }

    getAnnouncementBox()
    trackAmplitudeEvent()
  }, [])

  return (
    <HomePageDataLocalContext.Provider
      value={{
        dataBanner,
        dataMobileMenu,
        dataCities,
        dataTestimony,
        dataUsage,
        dataDealerArticle,
        dataTypeCar,
        dataCarofTheMonth,
        dataFooterMenu,
        dataMinMaxYearUsedCar,
        dataModelUsedCar,
      }}
    >
      <Script
        id="homepage-jsonld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(homepageJsonLd()),
        }}
        key="homepage-jsonld"
      />
      <HomepageMobile
        dataReccomendation={dataReccomendation}
        ssr={ssr}
        minMaxPrice={dataMinMaxPrice}
      />
    </HomePageDataLocalContext.Provider>
  )
}

export async function getServerSideProps(context: any) {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=59, stale-while-revalidate=3000',
  )

  const checkCookies = hasCookie(CookieKey.CityOtr, {
    req: context.req,
    res: context.res,
  })

  if (!checkCookies) {
    setCookies(CookieKey.CityOtr, defaultCity, {
      req: context.req,
      res: context.res,
    })
  }

  const getCityOtr = getCookies(CookieKey.CityOtr, {
    req: context.req,
    res: context.res,
  })

  const params = `?city=${getCityOtr.cityCode}&cityId=${getCityOtr.id}`

  const paramsUsedCar = new URLSearchParams()
  paramsUsedCar.append('query', '' as string)
  try {
    const [
      recommendationRes,
      bannerRes,
      menuMobileRes,
      citiesRes,
      testimonyRes,
      usageRes,
      mainArticleRes,
      typeCarRes,
      carofTheMonthRes,
      footerMenuRes,
      minmaxYearRes,
      modelUsedCarRes,
      dataSearchRes,
      minMaxPriceRes,
    ]: any = await Promise.allSettled([
      getRecommendation(params + '&sortBy=lowToHigh&limit=5&page=1'),
      getBanner(),
      getMobileHeaderMenu(),
      getCities(),
      getTestimony(''),
      getUsage(),
      getMainArticle('65'),
      getTypeCar('?city=jakarta'),
      getCarofTheMonth('?city=jakarta'),
      getMobileFooterMenu(),
      getMinMaxYearsUsedCar(''),
      getModelUsedCar(''),
      getUsedCarSearch('', { params: paramsUsedCar }),
      getMinMaxPrice(params),
    ])

    const [
      dataReccomendation,
      dataBanner,
      dataMobileMenu,
      dataCities,
      dataTestimony,
      dataUsage,
      dataMainArticle,
      dataDealerArticle,
      dataTypeCar,
      dataCarofTheMonth,
      dataFooterMenu,
      dataMinMaxYearUsedCar,
      dataModelUsedCar,
      dataSearchUsedCar,
      dataMinMaxPrice,
    ] = await Promise.all([
      recommendationRes.status === 'fulfilled'
        ? recommendationRes.value.carRecommendations
        : [],
      bannerRes.status === 'fulfilled' ? bannerRes.value.data : [],
      menuMobileRes.status === 'fulfilled' ? menuMobileRes.value.data : [],
      citiesRes.status === 'fulfilled' ? citiesRes.value : [],
      testimonyRes.status === 'fulfilled' ? testimonyRes.value.data : [],
      usageRes.status === 'fulfilled' ? usageRes.value.data.attributes : [],
      mainArticleRes.status === 'fulfilled' ? mainArticleRes.value : [],
      mainArticleRes.status === 'fulfilled'
        ? mainArticleRes.value.filter(
            (article: Article) => article.category === 'Review Otomotif',
          )
        : [],
      typeCarRes.status === 'fulfilled' ? typeCarRes.value : [],
      carofTheMonthRes.status === 'fulfilled'
        ? carofTheMonthRes.value.data
        : [],
      footerMenuRes.status === 'fulfilled' ? footerMenuRes.value.data : [],
      minmaxYearRes.status === 'fulfilled' ? minmaxYearRes.value.data : [],
      modelUsedCarRes.status === 'fulfilled' ? modelUsedCarRes.value.data : [],
      dataSearchRes.status === 'fulfilled' ? dataSearchRes.value.data : [],
      minMaxPriceRes.status === 'fulfilled'
        ? minMaxPriceRes.value
        : { minPriceValue: 0, maxPriceValue: 0 },
    ])

    return {
      props: {
        dataReccomendation,
        dataBanner,
        dataMobileMenu,
        dataCities,
        dataTestimony,
        dataUsage,
        dataMainArticle,
        dataDealerArticle,
        dataTypeCar,
        dataCarofTheMonth,
        dataMinMaxYearUsedCar,
        dataModelUsedCar,
        ssr: 'success',
        dataFooterMenu,
        dataSearchUsedCar,
        dataMinMaxPrice,
      },
    }
  } catch (error: any) {
    ReportSentry(`Index Pages - getServerSideProps`, error)
    return {
      props: {
        dataReccomendation: [],
        dataBanner: [],
        dataMobileMenu: [],
        dataCities: [],
        dataTestimony: [],
        dataUsage: [],
        dataMainArticle: [],
        dataDealerArticle: [],
        dataTypeCar: [],
        dataCarofTheMonth: [],
        dataMinMaxYearUsedCar: [],
        dataModelUsedCar: [],
        dataDesktopMenu: [],
        ssr: 'failed',
        dataFooterMenu: [],
        dataSearchUsedCar: [],
        dataPromoBanner: [],
        dataMinMaxPrice: { minPriceValue: 0, maxPriceValue: 0 },
      },
    }
  }
}

const homepageJsonLd = () => {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'SEVA by Astra',
    url: 'https://www.seva.id/',
    sameAs: [
      'https://www.facebook.com/sevabyastra/',
      'https://www.instagram.com/sevabyastra',
      'https://x.com/sevaid_official',
      'https://www.youtube.com/@sevabyastra',
      'https://id.linkedin.com/company/seva-by-astra',
    ],
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.seva.id/mobil-baru?search={search_term}',
      'query-input': 'required name=search_term',
    },
  }
}
