import {
  trackPromoBannerSeeAllClick,
  trackSeeAllPromoClick,
} from 'services/amplitude/seva20Tracking'
import React, { useMemo } from 'react'
import styles from 'styles/components/organisms/summary.module.scss'

import { IconChevronRight, IconPromo } from 'components/atoms'
import {
  CarVariantRecommendation,
  PromoDetail,
  WordpressPromoData,
  trackDataCarType,
} from 'utils/types/utils'
import elementId from 'utils/helpers/elementId/elementIds'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getLocalStorage } from 'utils/handler/localStorage'
import { LocalStorageKey, RemoteConfigKey, SessionStorageKey } from 'utils/enum'
import { getBrandAndModelValue } from 'utils/handler/getBrandAndModel'
import {
  getSessionStorage,
  saveSessionStorage,
} from 'utils/handler/sessionStorage'
import { defineRouteName } from 'utils/navigate'

import Link from 'next/link'
import { Swiper, SwiperSlide } from 'components/atoms/swiper'
import useRemoteConfigValue from 'services/context/remoteConfig'

export type PromoSectionProps = {
  setPromoName?: (value: string) => void
  dataForAmplitude?: any
  onButtonClick?: (value: boolean) => void
  cheapestVariantData?: CarVariantRecommendation | undefined
  info?: any
  onPage?: 'dealer' | 'VariantListPage' | 'Homepage'
  setSelectedTabValue?: (value: string) => void
  isOTO?: boolean
  dataFromApi?: WordpressPromoData[]
  dataFromCms?: PromoDetail[]
}

const PromoSection = ({
  setPromoName,
  dataForAmplitude,
  onButtonClick,
  info,
  onPage,
  isOTO = false,
  dataFromApi,
  dataFromCms,
}: PromoSectionProps) => {
  const router = useRouter()
  const brand = router.query.brand as string
  const model = router.query.model as string
  const enablePromoCumaDiSeva = isOTO

  const enablePromoWp = useRemoteConfigValue(
    RemoteConfigKey.ToggleEnablePromoWP,
    true,
  )

  const IsShowBadgeCreditOpportunity = getSessionStorage(
    SessionStorageKey.IsShowBadgeCreditOpportunity,
  )
  const filterStorage: any = getLocalStorage(LocalStorageKey.CarFilter)
  const dataCar: trackDataCarType | null = getSessionStorage(
    SessionStorageKey.PreviousCarDataBeforeLogin,
  )
  const isUsingFilterFinancial =
    !!filterStorage?.age &&
    !!filterStorage?.downPaymentAmount &&
    !!filterStorage?.monthlyIncome &&
    !!filterStorage?.tenure

  const trackCountlyClickSeeAll = () => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_PROMO_BANNER_ALL_CLICK)
  }
  const trackCountlyClicPromo = (promoUrl: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_PROMO_BANNER_CLICK, {
      PAGE_DIRECTION_URL: promoUrl,
      PROMO_ORDER: promoOrder,
    })
  }

  const trackCountlePromoCLick = (promoDetail: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_PROMO_CLICK, {
      CAR_BRAND: getBrandAndModelValue(brand),
      CAR_MODEL: getBrandAndModelValue(model),
      PROMO_DETAILS: promoDetail,
      PROMO_ORDER: promoOrder,
      PELUANG_KREDIT_BADGE:
        isUsingFilterFinancial && IsShowBadgeCreditOpportunity
          ? dataCar?.PELUANG_KREDIT_BADGE
          : 'Null',
      PAGE_ORIGINATION: 'PDP',
    })
  }

  const publishedPromo = useMemo(() => {
    if (dataFromApi && dataFromApi.length > 0) {
      return dataFromApi.filter(
        (item) =>
          item.publish_status === 'publish' &&
          item.show_homepage === true &&
          !!item.image_url &&
          !!item.permalink,
      )
    } else {
      return []
    }
  }, [dataFromApi])

  const publishedPromoCMS = useMemo(() => {
    if (dataFromCms && dataFromCms.length > 0) {
      return dataFromCms.filter(
        (item) =>
          item.promo_banner_detail_page.length > 1 &&
          item.promo_banner_thumbnail.length > 1,
      )
    } else {
      return []
    }
  }, [dataFromCms])

  const renderSeeAllPromoButton = () => {
    if (onPage === 'dealer') {
      return <></>
    } else if (onPage === 'VariantListPage' && !!info?.isPassengerCar) {
      return <></>
    } else if (onPage === 'VariantListPage' && !info?.isPassengerCar) {
      return (
        <a
          href="https://www.seva.id/info/promo/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.openSans}
          style={{ color: '#246ED4', paddingRight: '16px' }}
          onClick={() => trackSeeAllPromoClick(dataForAmplitude)}
          data-testid={elementId.PDP.CTA.LihatSemuaPromo}
        >
          Lihat Semua
        </a>
      )
    } else {
      return (
        <a
          href="https://www.seva.id/info/promo/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.openSansMedium}
          style={{ color: '#246ED4', paddingRight: '16px' }}
          onClick={() => {
            trackPromoBannerSeeAllClick()
            trackCountlyClickSeeAll()
            saveSessionStorage(
              SessionStorageKey.PageReferrerPromo,
              defineRouteName(
                window.location.pathname + window.location.search,
              ),
            )
          }}
          data-testid={elementId.Homepage.Promo.LihatSemua}
        >
          Lihat semua
        </a>
      )
    }
  }

  const renderListForHomepage = () =>
    publishedPromo.map((item, index) => {
      return (
        <SwiperSlide
          className={styles.bannerPromo}
          key={index}
          style={{
            width: onPage === 'dealer' ? 260.97 : 208,
          }}
        >
          <Link
            href={item.permalink}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              trackCountlyClicPromo(item.permalink, index + 1)
              saveSessionStorage(
                SessionStorageKey.PageReferrerPromo,
                defineRouteName(
                  window.location.pathname + window.location.search,
                ),
              )
              window.open(item.permalink, '_blank')
            }}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (e.button === 1 || e.button === 2) {
                trackCountlyClicPromo(item.permalink, index + 1)
                saveSessionStorage(
                  SessionStorageKey.PageReferrerPromo,
                  defineRouteName(
                    window.location.pathname + window.location.search,
                  ),
                )
              }
            }}
            data-testid={elementId.Homepage.Promo.DetailPromo}
          >
            <Image
              src={item.image_url}
              className={styles.promoBannerSmall}
              height={onPage === 'dealer' ? 196 : 156}
              width={onPage === 'dealer' ? 260.97 : 208}
              alt={item.title ?? 'Promo Illustration'}
              priority
            />

            <div
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), black)`,
              }}
            >
              <p className={styles.textPromoBanner}>
                Lihat detail
                <span className={styles.spacingChevronIcon}>
                  <IconChevronRight width={16} height={16} color="#FFFFFF" />
                </span>
              </p>
            </div>
          </Link>
        </SwiperSlide>
      )
    })

  const renderListWithCMSData = () => {
    return (
      <>
        {publishedPromoCMS.map((promo, index) => (
          <SwiperSlide
            key={index}
            className={styles.bannerPromo}
            onClick={() => {
              onButtonClick && onButtonClick(true)
              setPromoName && setPromoName(`promoCMS-${promo.promo_name}`)
              trackCountlePromoCLick(promo.promo_name, index + 1)
            }}
            data-testid={elementId.Homepage.Promo.DetailPromo}
            style={{
              width: 208,
              height: 156,
            }}
          >
            <Image
              src={promo.promo_banner_detail_page[0].media_url || ''}
              className={styles.promoBannerSmall}
              height={156}
              width={208}
              alt={promo.promo_name}
              loading="lazy"
            />

            <div
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), #13131B)`,
              }}
            >
              <p className={styles.textPromoBanner}>
                Lihat detail
                <span className={styles.spacingChevronIcon}>
                  <IconChevronRight width={16} height={16} color="#FFFFFF" />
                </span>
              </p>
            </div>
          </SwiperSlide>
        ))}
      </>
    )
  }

  const isHomeOrDealerPage = onPage === 'Homepage' || onPage === 'dealer'
  const isVariantListPage = onPage === 'VariantListPage'
  const noPublishedPromo = publishedPromo.length === 0
  const noPublishedPromoCMS = publishedPromoCMS.length === 0

  const shouldNotRender =
    !enablePromoWp ||
    (isHomeOrDealerPage && dataFromApi && noPublishedPromo) ||
    (isVariantListPage && noPublishedPromoCMS)

  if (shouldNotRender) return <></>

  return (
    <div>
      <div
        className={
          onPage === 'dealer'
            ? styles.cardPromoDetailDealer
            : styles.cardPromoDetail
        }
      >
        <div
          className={styles.row}
          style={{ justifyContent: 'space-between', paddingLeft: '16px' }}
        >
          <div className={styles.rowWithGap}>
            {onPage === 'VariantListPage' ? (
              <div className={styles.headerWrapper}>
                <IconPromo
                  width={24}
                  height={24}
                  color={'#B4231E'}
                  alt="SEVA Price Tag icon"
                />
                <h3 className={styles.kanyonMedium}>Promo</h3>
              </div>
            ) : (
              <h2
                className={styles.kanyonMediumBlue}
                data-testid={elementId.Homepage.Button.PromoEkslusif}
              >
                Promo Eksklusif
              </h2>
            )}
          </div>
          {renderSeeAllPromoButton()}
        </div>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          className={styles.rowScrollHorizontal}
        >
          {onPage === 'Homepage' || onPage === 'dealer'
            ? renderListForHomepage()
            : renderListWithCMSData()}
        </Swiper>
      </div>
    </div>
  )
}

export default PromoSection
